import React from "react"
import { Box, Flex } from "@theme-ui/components"
import { LanguageSwitcherContext } from "../context/languageSwitcherContext"
import { Link } from "./link"

const LanguageSwitcherMobile = () => {
  const languagesCountry = {
    it: "Italiano",
    en: "English",
    "en-us": "English (United States)",
    "en-ca": "English (Canada)",
    fr: "Français",
    de: "Deutsch",
    es: "Español",
    he: "Hebrew",
    pt: "Português",
    pl: "Polski",
    ru: "Русский",
  }
  return (
    <LanguageSwitcherContext.Consumer>
      {({ activeLocale, paths }) => {
        return (
          <Box sx={{ position: "relative" }}>
            <Flex
              as={"ul"}
              sx={{
                flexDirection: "column",
                padding: 2,
                paddingBottom: 7,
                paddingTop: 3,
                backgroundColor: "light",
                listStyle: "none",
              }}
            >
              {paths
                .sort((a, b) => a.locale.localeCompare(b.locale))
                .map((link, index) => (
                  <Box as="li" key={index}>
                    <Link
                      href={link.value}
                      sx={{
                        whiteSpace: "nowrap",
                        padding: 2,
                        paddingY: 2,
                        fontSize: 0,
                        display: "block",
                        borderRadius: "xs",
                        textTransform: "uppercase",
                        color:
                          activeLocale === link.locale ? "primary" : "dark",
                        backgroundColor:
                          activeLocale === link.locale
                            ? "lightBackground"
                            : "light",
                      }}
                    >
                      {languagesCountry[link.locale.toLowerCase()] ||
                        link.locale}
                    </Link>
                  </Box>
                ))}
            </Flex>
          </Box>
        )
      }}
    </LanguageSwitcherContext.Consumer>
  )
}

export default LanguageSwitcherMobile
