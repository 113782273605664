import { Box, Container, Flex, Grid, Text } from "@theme-ui/components"
import React, { useState } from "react"
import { useCompany } from "../hooks/useCompany"
import { useFooter } from "../hooks/useFooter"
import linkSwitch from "../utils/linkSwitch"
import { Link } from "./link"
import { i18nContext } from "../context/i18nContext"
import { getCookiePath, getPrivacyPath } from "../utils/path"
import SocialLinks from "./socialLinks"

const MobileFooter = () => {
  const footer = useFooter()
  const company = useCompany()

  return (
    <i18nContext.Consumer>
      {t => (
        <Box
          as="footer"
          sx={{ backgroundColor: "dark", pb: 5, color: "light" }}
        >
          <Container>
            <Grid columns={[1, 2]} gap={[0, 16]}>
              {/* COMPANY */}
              <Box sx={{ mb: 4 }}>
                <Box
                  sx={{
                    p: {
                      fontSize: 1,
                      lineHeight: 1.5,
                      strong: {
                        fontSize: 3,
                      },
                    },
                    "*:first-child": { mt: 0 },
                    a: {
                      color: "secondary",
                    },
                    mb: 4,
                  }}
                  dangerouslySetInnerHTML={{ __html: company.body }}
                />
                <Flex sx={{ flexDirection: "column" }}>
                  <Text dir="invalid" sx={{ fontWeight: "bold", color: "secondary", mb: 3 }}>
                    {t.followUs}
                  </Text>
                  <SocialLinks />
                </Flex>
              </Box>
              <Box dir={t.locale === "he" ? "rtl" : "auto"}>
                {footer.map(column => (
                  <List key={column.id}>
                    {column.treeChildren.map(link => (
                      <Accordion link={link} />
                    ))}
                  </List>
                ))}
              </Box>
            </Grid>
            {/* BOTTOM */}
            <Flex
              sx={{
                flexDirection: ["column"],
                mt: 4,
              }}
            >
              <Flex
                sx={{
                  fontSize: 0,
                  flexDirection: "row",
                  a: {
                    color: "light",
                    mx: 2,
                    "&:hover": {
                      opacity: 0.8,
                    },
                    "&:first-child": {
                      ml: 0,
                    },
                    "&:last-child": {
                      mr: 0,
                    },
                  },
                }}
              >
                <Link to={getPrivacyPath(t.locale)}>Privacy Policy</Link>
                <Link to={getCookiePath(t.locale)}>Cookie Policy</Link>
              </Flex>
              <Box sx={{ marginTop: 3 }}>
                <Text as="p" sx={{ fontSize: 0 }}>
                  Copyright 2021 AB HOLDING SPA - P.IVA 02243290984 - REA 433585
                  - Reg. imp. BS02243290984 - Cap. sociale 6.000.000 i.v.
                </Text>
              </Box>
            </Flex>
          </Container>
        </Box>
      )}
    </i18nContext.Consumer>
  )
}

const Accordion = ({ link }) => {
  const [open, setOpen] = useState(false)
  return (
    <Item>
      <Text
        dir="invalid"
        sx={{
          fontWeight: "bold",
          pt: 2,
          display: "block",
          color: "secondary",
          borderTop: "1px solid",
          borderColor: "#ffffff40",
          cursor: "pointer",
        }}
        onClick={() => setOpen(!open)}
      >
        {link.link ? linkSwitch(link.link) : link.anchor}
      </Text>
      {open && (
        <InnerList key={link.id}>
          {link.treeChildren.map(link => (
            <InnerItem>
              {link.link ? linkSwitch(link.link) : link.anchor}
            </InnerItem>
          ))}
        </InnerList>
      )}
    </Item>
  )
}

const List = props => {
  return (
    <Flex
      {...props}
      sx={{
        flexDirection: "column",
        margin: 0,
        padding: 0,
        listStyle: "none",
        a: {
          textDecoration: "none",
          color: "light",
          "&:hover": {
            textDecoration: "underline",
          },
        },
      }}
      as="ul"
    />
  )
}

const InnerList = props => {
  return (
    <Flex
      {...props}
      sx={{
        flexDirection: "column",
        margin: 0,
        padding: 0,
        mt: 2,
        listStyle: "none",
        a: {
          textDecoration: "none",
          color: "light",
          "&:hover": {
            textDecoration: "underline",
          },
        },
      }}
      as="ul"
    />
  )
}

const Item = props => {
  return (
    <Box {...props} as="li" sx={{ mb: 0, py: 1, "&:last-child": { mb: 0 } }} />
  )
}

const InnerItem = props => {
  return (
    <Box {...props} as="li" sx={{ mb: 0, py: 2, "&:last-child": { mb: 0 } }} />
  )
}

export default MobileFooter
