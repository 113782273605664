import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { LanguageSwitcherContext } from "../context/languageSwitcherContext"

export const useContact = () => {
  const menu = useStaticQuery(graphql`
    query ContactQuery {
      allDatoCmsContact {
        nodes {
          id
          locale
          linkOne {
            id
            anchor
            locale
            model {
              apiKey
            }
            link {
              ... on DatoCmsBlogPage {
                ...BlogDetails
              }
              ... on DatoCmsPage {
                ...PageDetails
                ...PageTreeParent
                ...AllSlugLocales
              }
              ... on DatoCmsArticle {
                ...ArticleDetails
                ...ArticleAllSlugLocales
              }
              ... on DatoCmsArticleCategory {
                ...ArticleCategoryDetails
                ...ArticleCategoryAllSlugLocales
              }
            }
          }
          subtitleLinkOne
          linkTwo {
            id
            anchor
            locale
            model {
              apiKey
            }
            link {
              ... on DatoCmsBlogPage {
                ...BlogDetails
              }
              ... on DatoCmsPage {
                ...PageDetails
                ...PageTreeParent
                ...AllSlugLocales
              }
              ... on DatoCmsArticle {
                ...ArticleDetails
                ...ArticleAllSlugLocales
              }
              ... on DatoCmsArticleCategory {
                ...ArticleCategoryDetails
                ...ArticleCategoryAllSlugLocales
              }
            }
          }
          subtitleLinkTwo
          model {
            apiKey
          }
        }
      }
    }
  `)

  const locale = React.useContext(LanguageSwitcherContext).activeLocale

  const i18nCompany = menu.allDatoCmsContact.nodes.filter(
    contact => contact.locale === locale
  )

  return i18nCompany[0]
}
