import React from "react"
import {
  getArticleCategoryPath,
  getArticlePath,
  getBlogPath,
  getPagePath,
} from "./path"
import { Link as ThemeUiLink } from "theme-ui"

function linkSwitch(item, locale, section) {
  function trackLink(href, section) {
    if (typeof window !== "undefined" && window.dataLayer !== undefined) {
      window.dataLayer.push({
        event: "click_internal_link",
        link_href: href || null,
        link_section: section || null,
      })
    }
  }
  if (item.link) {
    switch (item.link.model.apiKey) {
      case "page":
        return (
          <ThemeUiLink
            href={getPagePath(item.link, locale)}
            onClick={() => trackLink(getPagePath(item.link, locale), section)}
          >
            {item.anchor}
          </ThemeUiLink>
        )
      case "blog_page":
        return (
          <ThemeUiLink
            href={getBlogPath(locale)}
            onClick={() => trackLink(getBlogPath(locale), section)}
          >
            {item.anchor}
          </ThemeUiLink>
        )
      case "event_page":
        return (
          <ThemeUiLink
            href={getPagePath(item.link, locale)}
            onClick={() => trackLink(getPagePath(item.link, locale), section)}
          >
            {item.anchor}
          </ThemeUiLink>
        )
      case "article":
        return (
          <ThemeUiLink
            href={getArticlePath(item.link, locale)}
            onClick={() =>
              trackLink(getArticlePath(item.link, locale), section)
            }
          >
            {item.anchor}
          </ThemeUiLink>
        )
      case "article_category":
        return (
          <ThemeUiLink
            href={getArticleCategoryPath(item.link, locale)}
            onClick={() =>
              trackLink(getArticleCategoryPath(item.link, locale), section)
            }
          >
            {item.anchor}
          </ThemeUiLink>
        )
      default:
        return null
    }
  }
  return (
    <ThemeUiLink href={item.url} target="blank" rel="nofollow noopener">
      {item.anchor}
    </ThemeUiLink>
  )
}

export default linkSwitch
