import React from "react"
import { Box, Flex, Grid, Heading, Text } from "@theme-ui/components"
import { GatsbyImage } from "gatsby-plugin-image"
import { Link } from "./link"

const ArticleThumb = ({ article, getPath, trim }) => {
  return (
    <Box sx={{ borderTop: "2px solid", borderColor: "primary" }}>
      <Link
        href={getPath(article, article.locale)}
        sx={{ textDecoration: "none" }}
      >
        <Flex sx={{ flexDirection: "column" }}>
          {article.heroImage && (
            <Box
              sx={{
                mb: 2,
                ".gatsby-image-wrapper": {
                  height: "100%",
                },
              }}
            >
              <GatsbyImage image={article.heroImage.list} alt="" />
            </Box>
          )}
          <Flex
            sx={{
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ mb: 2 }}>
              <Text
                dir="invalid"
                variant="caption"
                sx={{
                  color: "primary",
                  textTransform: "uppercase",
                  fontWeight: "bold",
                  mr: 2,
                }}
              >
                {article.category.title}
              </Text>
              {article.meta && (
                <Text
                  dir="invalid"
                  sx={{
                    fontWeight: "bold",
                  }}
                  variant="caption"
                >
                  {article.meta.firstPublishedAt}
                </Text>
              )}
            </Box>
            <Heading
              as="h2"
              variant="h6"
              sx={{ mb: 3, mt: 0, color: "text", textTransform: "uppercase" }}
              dir="invalid"
            >
              {article.title}
            </Heading>
          </Flex>
        </Flex>
      </Link>
    </Box>
  )
}

export default ArticleThumb
