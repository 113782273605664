import { Box, Flex } from "@theme-ui/components"
import { motion } from "framer-motion"
import { Link } from "../components/link"

const AnimatedBox = motion(Box)
const AnimatedFlex = motion(Flex)
const AnimatedLink = motion(Link)

export default AnimatedBox
export { AnimatedFlex, AnimatedLink }
