import { Box, Container, Grid, Text } from "@theme-ui/components"
import React from "react"
import linkSwitch from "../utils/linkSwitch"
import { i18nContext } from "../context/i18nContext"
import { useContact } from "../hooks/useContact"

const Contact = () => {
  const contact = useContact()
  // console.log(contact)

  return (
    <i18nContext.Consumer>
      {t => (
        <Box sx={{ backgroundColor: "secondary", mt: [4, 4, 5, 7] }}>
          <Container>
            <Grid columns={[1, 2]}>
              <Box>
                <Text
                  dir="invalid"
                  sx={{
                    a: {
                      fontWeight: "bold",
                      pt: 2,
                      display: "block",
                      color: "light",
                      textDecoration: "none",
                      fontSize: 3,
                      "&:hover": {
                        textDecoration: "underline",
                      },
                    },
                  }}
                >
                  {linkSwitch(contact.linkOne)}
                </Text>
                <Text>{contact.subtitleLinkOne}</Text>
              </Box>
              <Box>
                <Text
                  sx={{
                    a: {
                      fontWeight: "bold",
                      pt: 2,
                      display: "block",
                      color: "light",
                      fontSize: 3,
                      textDecoration: "none",
                      "&:hover": {
                        textDecoration: "underline",
                      },
                    },
                  }}
                >
                  {linkSwitch(contact.linkTwo)}
                </Text>
                <Text>{contact.subtitleLinkTwo}</Text>
              </Box>
            </Grid>
          </Container>
        </Box>
      )}
    </i18nContext.Consumer>
  )
}

export default Contact
