import React, { useEffect, useState } from "react"
import { Box, Image } from "@theme-ui/components"
import ReactModal from "react-modal"
import AnimatedBox, { AnimatedFlex } from "../../utils/motion"
import { Flex } from "theme-ui"
import chat from "../../images/chat-bot.jpg"
import { useBreakpointIndex } from "@theme-ui/match-media"
import { i18nContext } from "../../context/i18nContext"

const ChatBox = ({}) => {
  const [open, setOpen] = useState(false)
  function getLocalizedForm(locale) {
    const forms = {
      it: "/forms/form-it.html",
      en: "/forms/form-en.html",
      "en-us": "/forms/form-en-US.html",
      "en-ca": "/forms/form-en-CA.html",
      fr: "/forms/form-fr.html",
      de: "/forms/form-de.html",
      pt: "/forms/form-pt.html",
      es: "/forms/form-es.html",
      pl: "/forms/form-pl.html",
      ru: "/forms/form-ru.html",
    }
    return forms[locale] ?? "/forms/form-en.html"
  }
  const index = useBreakpointIndex()
  ReactModal.defaultStyles = {
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(0, 0, 0, 0.75)",
      zIndex: 5000,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    content: {
      width: index === 0 ? "90vw" : "644px",
      padding: "0 !important",
      background: "#000",
      overflow: "auto",
      WebkitOverflowScrolling: "touch",
      borderRadius: "4px",
      outline: "none",
      maxHeight: "80vh",
    },
  }

  useEffect(() => {
    console.log(open, "chatOpen")
    if (
      typeof window !== "undefined" &&
      window.dataLayer !== undefined &&
      open === true
    ) {
      window.dataLayer.push({
        event: "chat_open",
      })
    }
  }, [open])

  return (
    <i18nContext.Consumer>
      {t => (
        <Box>
          <ReactModal
            isOpen={open}
            onRequestClose={() => setOpen(false)}
            ariaHideApp={false}
            closeTimeoutMS={500}
          >
            <iframe
              src={getLocalizedForm(t.locale)}
              title="dummy"
              style={{ width: "100%", minHeight: "60vh", border: "none" }}
            ></iframe>
          </ReactModal>

          <Flex
            sx={{
              position: "fixed",
              bottom: ["16px", "32px"],
              right: ["16px", "32px"],
              textAlign: "right",
              flexDirection: ["row", "row", "column", "column"],
              alignItems: ["center", "center", "flex-end"],
              zIndex: 9999,
            }}
          >
            <AnimatedFlex
              initial={{ scale: 0.9, opacity: 0, y: 0 }}
              animate={{
                scale: [null, 1, 1, 0.9],
                opacity: [null, 1, 1, 0],
                y: [0, 0, 0, 100],
                transition: {
                  duration: 4,
                  type: "spring",
                  damping: 10,
                  mass: 0.75,
                  stiffness: 100,
                  delay: 2.7,
                  times: [0, 0.1, 0.95, 1],
                },
              }}
              sx={{ alignItems: "center", cursor: "pointer" }}
            >
              <Box
                sx={{
                  px: 3,
                  py: 3,
                  borderRadius: "sm",
                  backgroundColor: "light",
                  boxShadow:
                    "0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%)",
                  mb: [0, 0, 2],
                  mr: [2, 2, 0],
                  maxWidth: ["100%", "100%", "240px"],
                  fontSize: [1, 1, 2],
                }}
                onClick={() => {
                  setOpen(true)
                }}
              >
                {t.chat}
              </Box>
            </AnimatedFlex>
            <Box sx={{ position: "relative", cursor: "pointer" }}>
              <AnimatedBox
                initial={{ y: 50, opacity: 0, scale: 0.8 }}
                animate={{
                  y: 0,
                  opacity: 1,
                  scale: 1,
                  transition: {
                    duration: 0.4,
                    type: "spring",
                    damping: 10,
                    mass: 0.5,
                    stiffness: 100,
                    delay: 2,
                  },
                }}
                sx={{
                  marginRight: 0,
                  height: "72px",
                  width: "72px",
                  borderRadius: "full",
                  overflow: "hidden",
                  position: "relative",
                  right: 0,
                  boxShadow:
                    "0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%)",
                }}
                onClick={() => {
                  setOpen(true)
                }}
              >
                <Image src={chat} />
              </AnimatedBox>
              <AnimatedFlex
                initial={{ opacity: 0, scale: 0.5 }}
                animate={{
                  opacity: 1,
                  scale: 1,
                  transition: {
                    duration: 0.4,
                    type: "spring",
                    damping: 10,
                    mass: 0.5,
                    stiffness: 100,
                    delay: 2.3,
                  },
                }}
                sx={{
                  marginRight: 0,
                  height: "20px",
                  width: "20px",
                  borderRadius: "full",
                  overflow: "hidden",
                  backgroundColor: "primary",
                  position: "absolute",
                  right: 0,
                  top: 0,
                  color: "light",
                  boxShadow:
                    "0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%)",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                1
              </AnimatedFlex>
            </Box>
          </Flex>
        </Box>
      )}
    </i18nContext.Consumer>
  )
}

export default ChatBox
