import React from "react"

export const languages = {
  en: {
    locale: "en",
    discoverMore: "Discover more",
    all: "All",
    search: "Search",
    results: "Results",
    noResults: "No results",
    download: "Download",
    latestArticles: "Latest news",
    previous: "previous",
    next: "next",
    allCategories: "All categories",
    abFor: "AB for",
    followUs: "Follow us",
    chat: "Book a free consultation online",
    join: "Join",
    caseStudy: "Read the case study",
  },
  he: {
    locale: "he",
    discoverMore: "למידע נוסף",
    all: "All",
    search: "חיפוש",
    results: "Results",
    noResults: "No results",
    download: "Download",
    latestArticles: "Latest news",
    previous: "previous",
    next: "next",
    allCategories: "All categories",
    abFor: "AB עבור",
    followUs: "Follow us",
    chat: "Book a free consultation online",
    join: "Join",
    caseStudy: "Read the case study",
  },
  de: {
    locale: "de",
    discoverMore: "Discover more",
    all: "All",
    search: "Search",
    results: "Results",
    noResults: "No results",
    download: "Download",
    latestArticles: "Latest news",
    previous: "previous",
    next: "next",
    allCategories: "All categories",
    abFor: "AB for",
    followUs: "Follow us",
    chat: "Eine kostenlose Beratung online buchen",
    join: "Join",
    caseStudy: "Lesen Sie die Fallstudie",
  },
  es: {
    locale: "es",
    discoverMore: "Discover more",
    all: "All",
    search: "Search",
    results: "Results",
    noResults: "No results",
    download: "Descargar",
    latestArticles: "Latest news",
    previous: "previous",
    next: "next",
    allCategories: "All categories",
    abFor: "AB for",
    followUs: "Follow us",
    chat: "Reserve una consulta gratuita en línea",
    join: "Join",
    caseStudy: "Leer el caso",
  },
  pt: {
    locale: "pt",
    discoverMore: "Discover more",
    all: "All",
    search: "Search",
    results: "Results",
    noResults: "No results",
    download: "Descarregar",
    latestArticles: "Latest news",
    previous: "previous",
    next: "next",
    allCategories: "All categories",
    abFor: "AB for",
    followUs: "Follow us",
    chat: "Marcar uma consulta on-line gratuita",
    join: "Join",
    caseStudy: "Leia o estudo de caso",
  },
  pl: {
    locale: "pl",
    discoverMore: "Discover more",
    all: "All",
    search: "Search",
    results: "Results",
    noResults: "No results",
    download: "Download",
    latestArticles: "Latest news",
    previous: "previous",
    next: "next",
    allCategories: "All categories",
    abFor: "AB for",
    followUs: "Follow us",
    chat: "Zarezerwuj bezpłatną konsultację online",
    join: "Join",
    caseStudy: "Przeczytaj studium przypadku",
  },
  ru: {
    locale: "ru",
    discoverMore: "Discover more",
    all: "All",
    search: "поиск",
    results: "Results",
    noResults: "No results",
    download: "Download",
    latestArticles: "Latest news",
    previous: "previous",
    next: "next",
    allCategories: "All categories",
    abFor: "AB for",
    followUs: "Follow us",
    chat: "Заказать бесплатную консультацию онлайн",
    join: "Join",
    caseStudy: "Ознакомьтесь с примером из практики",
  },
  "en-us": {
    locale: "en-US",
    discoverMore: "Discover more",
    all: "All",
    search: "Search",
    results: "Results",
    noResults: "No results",
    download: "Download",
    latestArticles: "Latest news",
    previous: "previous",
    next: "next",
    allCategories: "All categories",
    abFor: "AB for",
    followUs: "Follow us",
    chat: "Book a free consultation online",
    join: "Join",
    caseStudy: "Read the case study",
  },
  "en-ca": {
    locale: "en-CA",
    discoverMore: "Discover more",
    all: "All",
    search: "Search",
    results: "Results",
    noResults: "No results",
    download: "Download",
    latestArticles: "Latest news",
    previous: "previous",
    next: "next",
    allCategories: "All categories",
    abFor: "AB for",
    followUs: "Follow us",
    chat: "Book a free consultation online",
    join: "Join",
    caseStudy: "Read the case study",
  },
  fr: {
    locale: "fr",
    discoverMore: "Discover more",
    all: "All",
    search: "Search",
    results: "Results",
    noResults: "No results",
    download: "Télécharger",
    latestArticles: "Latest news",
    previous: "previous",
    next: "next",
    allCategories: "All categories",
    abFor: "AB for",
    followUs: "Follow us",
    chat: "Réservez un conseil gratuite en ligne",
    join: "Join",
    caseStudy:
      "Remplissez le formulaire pour recevoir la brochure d'information gratuite",
  },
  it: {
    locale: "it",
    discoverMore: "Scopri di più",
    all: "Tutti",
    search: "Cerca",
    results: "risultati",
    noResults: "Nessun risultato",
    download: "Scarica",
    latestArticles: "Ultime news",
    previous: "precedente",
    next: "successivo",
    allCategories: "Tutte le categorie",
    abFor: "AB for",
    followUs: "Seguici su",
    chat: "Prenota una consulenza gratuita online",
    join: "Accedi",
    caseStudy: "Leggi il caso studio",
  },
}

export const i18nContext = React.createContext(languages.it)
