import { Box, Container, Flex } from "@theme-ui/components"
import React, { useEffect, useState } from "react"
import { getHomePath, getStoryPath } from "../utils/path"
import { Link } from "./link"
import linkSwitch from "../utils/linkSwitch"
import { LanguageSwitcherContext } from "../context/languageSwitcherContext"
import logo from "../images/logo-ab.svg"
import { Image, Grid, Text } from "@theme-ui/components"
import { useMegaMenu } from "../hooks/useMegaMenu"
import { AnimatePresence } from "framer-motion"
import AnimatedBox from "../utils/motion"
import ArticleThumb from "./articleThumb"
import { i18nContext } from "../context/i18nContext"
import { ArrowLeft, ArrowRight } from "react-feather"
import SwiperCore, { Navigation, Mousewheel, A11y } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/swiper-bundle.min.css"
import { debounce } from "lodash"
SwiperCore.use([Mousewheel, Navigation, A11y])

const MegaMenu = ({ theme, hideMenu }) => {
  const [show, setShow] = useState(false)
  const [selectedItem, setSelectedItem] = useState(0)
  const [selectedSubItem, setSelectedSubItem] = useState(0)
  const [currentTheme, setCurrentTheme] = useState(theme)
  const [active, setActive] = useState(false)
  const locale = React.useContext(LanguageSwitcherContext).activeLocale
  const menu = useMegaMenu()

  const [isHovered, setIsHovered] = useState(false)

  const debouncedHandleMouseEnterMenu = debounce(index => {
    setActive(true)
    setCurrentTheme("dark")
    setIsHovered(true)
    setShow(true)
    setSelectedItem(index)
  }, 200)

  const handlOnMouseLeaveMenu = () => {
    // console.log("selectedItem",selectedItem)
    // console.log("selectedSubItem",selectedSubItem)
    setActive(false)
    setCurrentTheme("light")
    setShow(false)
    setIsHovered(false)

    debouncedHandleMouseEnterMenu.cancel()
  }

  useEffect(() => {
    setSelectedSubItem(0)
  }, [selectedItem])

  return (
    <Box
      as="nav"
      sx={{
        backgroundColor: active ? "white" : "transparent",
        position: "relative",
      }}
      onMouseLeave={() => handlOnMouseLeaveMenu()}
    >
      <Container variant="header" sx={{ paddingX: [3, 4] }}>
        <Flex
          sx={{
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "stretch",
          }}
        >
          <Box sx={{ paddingX: 2, paddingY: 3 }}>
            <Link to={getHomePath(locale)}>
              <Image src={logo} sx={{ minWidth: "200px", width: "200px" }} />
            </Link>
          </Box>
          {!hideMenu && (
            <Flex
              sx={{
                flexDirection: "row",
                padding: 0,
                margin: 0,
                listStyle: "none",
                maxWidth: ["70%", "70%", "70%", "80%"],
              }}
              as="ul"
            >
              <Flex
                as="div"
                sx={{
                  marginLeft: "0.25rem",
                  color: "primary",
                  alignItems: "center",
                  border: "none",
                  background: "none",
                  cursor: "pointer",
                  minWidth: "32px",
                  minHeight: "32px",
                  padding: "1px 6px",
                  "&.swiper-button-disabled": {
                    opacity: 0,
                  },
                }}
                className="prev-collection-megamenu"
              >
                <ArrowLeft size={32} />
              </Flex>
              <i18nContext.Consumer>
                {t => (
                  <Flex
                    sx={{
                      paddingX: 2,
                      paddingY: 3,
                      color: "primary",
                      fontWeight: "bold",
                      alignItems: "center",
                      minWidth: "80px",
                    }}
                  >
                    <Box sx={{ minWidth: "80px" }}>{t.abFor}</Box>
                  </Flex>
                )}
              </i18nContext.Consumer>
              <Flex
                onMouseEnter={() => {
                  // setActive(true)
                  // setCurrentTheme("dark")
                }}
                onMouseLeave={() => {
                  // setActive(false)
                  // setCurrentTheme("light")
                }}
                sx={{
                  ".swiper-slide": {
                    width: "fit-content",
                    display: "flex",
                    alignItems: "stretch",
                  },
                }}
              >
                <Swiper
                  spaceBetween={8}
                  slidesPerView={"auto"}
                  navigation={{
                    nextEl: ".next-collection-megamenu",
                    prevEl: ".prev-collection-megamenu",
                  }}
                  watchOverflow
                >
                  {menu.map((item, index) => (
                    <SwiperSlide>
                      <Box
                        sx={{ display: "inline-flex", alignItems: "stretch" }}
                      >
                        <Menu
                          item={item}
                          locale={locale}
                          key={item.id}
                          theme={currentTheme}
                          setShow={setShow}
                          index={index}
                          setSelectedItem={setSelectedItem}
                          setActive={setActive}
                          setCurrentTheme={setCurrentTheme}
                          debouncedHandleMouseEnterMenu={
                            debouncedHandleMouseEnterMenu
                          }
                        />
                      </Box>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </Flex>
              <Flex
                as="div"
                sx={{
                  marginLeft: "0.25rem",
                  color: "primary",
                  alignItems: "center",
                  border: "none",
                  background: "none",
                  cursor: "pointer",
                  padding: "1px 6px",
                  "&.swiper-button-disabled": {
                    opacity: 0,
                  },
                  minWidth: "32px",
                  minHeight: "32px",
                }}
                className="next-collection-megamenu"
              >
                <ArrowRight size={32} />
              </Flex>
            </Flex>
          )}
        </Flex>
      </Container>
      {menu.map((item, index) => (
        <AnimatePresence>
          {show && selectedItem === index && (
            <Box
              sx={{
                backgroundColor: "light",
                position: "absolute",
                top: "120px",
                paddingTop: "3rem",
                left: 0,
                width: "100%",
                minHeight: "50vh",
                boxShadow:
                  "0 5px 7px rgb(50 50 93 / 8%), 0 5px 4px rgb(0 0 0 / 5%)",
                overflow: "hidden",
              }}
              onMouseOver={() => {
                // setShow(true)
                // setCurrentTheme("dark")
                // setActive(true)
              }}
              onMouseOut={() => {
                // setShow(false)
                // setCurrentTheme("light")
                // setActive(false)
              }}
            >
              <AnimatedBox
                initial={{ opacity: 0, y: 50 }}
                animate={{
                  opacity: 1,
                  y: 0,
                  transition: {
                    duration: 0.6,
                    type: "spring",
                    damping: 10,
                    mass: 0.5,
                    stiffness: 100,
                    delay: 0.2,
                  },
                }}
              >
                <Tab
                  item={item}
                  locale={locale}
                  setShow={setShow}
                  setCurrentTheme={setCurrentTheme}
                  setActive={setActive}
                />
              </AnimatedBox>
            </Box>
          )}
        </AnimatePresence>
      ))}
    </Box>
  )
}

const Menu = ({
  item,
  locale,
  theme,
  index,
  setActive,
  setCurrentTheme,
  setShow,
  setSelectedItem,
  debouncedHandleMouseEnterMenu,
}) => {
  const componentTheme = theme || "light"
  const light = componentTheme === "light" ? "light" : "dark"

  return (
    <Flex
      as="li"
      key={item.id}
      sx={{
        paddingX: 2,
        paddingY: 3,
        // fontWeight: "bold",
        alignItems: "center",
        color: light,
        "&:last-child": {
          ul: {
            right: 0,
          },
        },
      }}
    >
      <Box
        sx={{
          cursor: "default",
          "&:hover": {
            textDecoration: "underline",
          },
        }}
        onMouseOver={() => debouncedHandleMouseEnterMenu(index)}
      >
        {item.anchor}
      </Box>
    </Flex>
  )
}

const Tab = ({ item, locale, setShow, setCurrentTheme, setActive }) => {
  const [selectedSubItem, setSelectedSubItem] = useState(0)
  return (
    <Container sx={{ paddingTop: 6 }}>
      {item.treeChildren.length > 1 && (
        <Flex
          as="ul"
          sx={{
            listStyle: "none",
            margin: 0,
            padding: 0,
            marginBottom: 6,
            width: "max-content",
            maxWidth: "100%",
            borderRadius: "sm",
            ".swiper-slide": {
              width: "fit-content",
              display: "flex",
              alignItems: "stretch",
            },
          }}
        >
          <Swiper
            spaceBetween={8}
            slidesPerView={"auto"}
            // navigation={{
            //   nextEl: ".next-collection-megamenu",
            //   prevEl: ".prev-collection-megamenu",
            // }}
            watchOverflow
            draggable
          >
            {item.treeChildren.map((item, index) => (
              <SwiperSlide>
                <Box
                  sx={{
                    cursor: "grab",
                    color: "primary",
                    fontWeight: "normal",
                    display: "inline-flex",
                    alignItems: "stretch",
                    marginRight: 4,
                    textDecoration:
                      index === selectedSubItem ? "underline" : "none",
                  }}
                  onMouseOver={() => setSelectedSubItem(index)}
                >
                  {item.anchor}
                </Box>
              </SwiperSlide>
            ))}
          </Swiper>
        </Flex>
      )}
      <Grid columns={4}>
        {item.treeChildren[selectedSubItem].treeChildren.map(item => (
          <>
            <Box>
              <Text
                dir="invalid"
                as="p"
                sx={{
                  color: "lightGrey",
                  fontWeight: "normal",
                  mb: 3,
                }}
              >
                {item.anchor}
              </Text>
              <Box
                as="ul"
                sx={{
                  listStyle: "none",
                  padding: 0,
                  margin: 0,
                }}
              >
                {item.treeChildren.map(subItem => (
                  <Box
                    as="li"
                    sx={{
                      a: {
                        color: "dark",
                        fontWeight: "normal",
                        textDecoration: "none",
                        "&:hover": {
                          textDecoration: "underline",
                        },
                      },
                      mb: 3,
                    }}
                  >
                    {linkSwitch(subItem.link, locale, "megaMenu")}
                  </Box>
                ))}
              </Box>
            </Box>
          </>
        ))}
        <Box
          onMouseOver={() => {
            setShow(true)
            setCurrentTheme("dark")
            setActive(true)
          }}
        >
          {item.treeChildren[selectedSubItem].story && (
            <ArticleThumb
              article={item.treeChildren[selectedSubItem].story}
              getPath={getStoryPath}
            />
          )}
        </Box>
      </Grid>
    </Container>
  )
}

export default MegaMenu
