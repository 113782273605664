import { Box } from "@theme-ui/components"
import React from "react"
import MobileNav from "./mobileNav"
import MegaMenu from "./megaMenu"
import SecondaryNav from "./secondaryNav"

const Header = ({ theme, hideMenu }) => {
  return (
    <Box
      as="header"
      sx={{
        position: "absolute",
        width: "100%",
        zIndex: "9999",
      }}
      dir="auto"
    >
      <Box sx={{ display: ["none", "none", "none", "block"] }}>
        <SecondaryNav />
        <MegaMenu theme={theme} hideMenu={hideMenu} />
      </Box>
      <Box sx={{ display: ["block", "block", "block", "none"] }}>
        <MobileNav theme={theme} />
      </Box>
    </Box>
  )
}

export default Header
