import React from "react"
import { Helmet } from "react-helmet"

const Hreflang = ({ siteUrl, paths }) => {
  // console.log(paths)
  return (
    <>
      {paths.map((link, index) => (
        <>
          {link.locale === "en" ? (
            <Helmet>
              <link rel="alternate" hreflang="x-default" href={siteUrl + "/"} />
              <link
                rel="alternate"
                hreflang={link.locale}
                href={link.value === "/" ? siteUrl + "/" : siteUrl + link.value}
                key={index}
              />
            </Helmet>
          ) : (
            <Helmet>
              <link
                rel="alternate"
                hreflang={link.locale}
                href={siteUrl + link.value}
                key={index}
              />
            </Helmet>
          )}
        </>
      ))}
    </>
  )
}

export default Hreflang
